import React from 'react';
import {Div} from "./contact"
import { useForm } from 'react-hook-form';

const ContactMe = () => {
  const {
    register,
    handleSubmit,
    // reset,
    formState: { errors }
  } = useForm();
  
  const onSubmit = async (data) => {
    const { name, email, subject, message } = data;
    
    console.log('Name: ', name);
    console.log('Email: ', email);
    console.log('Subject: ', subject);
    console.log('Message: ', message);
  };

  return (
    <main>
        <Div className='ContactForm'>
            <div className='container'>
                <div className='row'>
                <div className='col-12 text-center'>
                    <div className='contactForm'>
                    <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                        {/* Row 1 of form */}
                        <div className='row formRow'>
                        <div className='col-6'>
                            <label>Name:</label>
                            <br/>
                            <input
                            type='text'
                            name='name'
                            {...register('name', {
                                required: { value: true, message: 'Please enter your name' },
                                maxLength: {
                                value: 30,
                                message: 'Please use 30 characters or less'
                                }
                            })}
                            className='form-control formInput'
                              placeholder='John Doe'
                            ></input>
                            {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
                        </div>
                        <div className='col-6'>
                        <br/>
                        <label>Email:</label>
                            <br/>
                            <input
                            type='email'
                            name='email'
                            {...register('email', {
                                required: true,
                                pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                            })}
                            className='form-control formInput'
                              placeholder='email@address.com'
                            ></input>
                            {errors.email && (
                            <span className='errorMessage'>Please enter a valid email address</span>
                            )}
                        </div>
                        </div>
                        {/* Row 2 of form */}
                        <div className='row formRow'>
                        <div className='col'>
                        <br/>
                        <label>Subject:</label>
                            <br/>
                            <input
                            type='text'
                            name='subject'
                            {...register('subject', {
                                required: { value: true, message: 'Please enter a subject' },
                                maxLength: {
                                value: 75,
                                message: 'Subject cannot exceed 75 characters'
                                }
                            })}
                            className='form-control formInput'
                            //   placeholder='Subject'
                            ></input>
                            {errors.subject && (
                            <span className='errorMessage'>{errors.subject.message}</span>
                            )}
                        </div>
                        </div>
                        {/* Row 3 of form */}
                        <div className='row formRow'>
                        <div className='col'>
                        <br/>
                        <label>Message:</label>
                            <br/>
                            <textarea
                            rows={3}
                            name='message'
                            {...register('message', {
                                required: true
                            })}
                            className='form-control formInput'
                            // placeholder='Message'
                            ></textarea>
                            {errors.message && <span className='errorMessage'>Please enter a message</span>}
                        </div>
                        </div>
                        <button className='submit-btn' type='submit'>
                        Submit
                        </button>
                    </form>
                    </div>
                </div>
                </div>
            </div>
        </Div>
    </main>
  );
};

export default ContactMe;